import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import ScrollMagic from "scrollmagic";
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/app-dev-phone.svg';

var app1 = require('./images/app-development.svg');
const images = require.context('./images', true);

export class Appdev extends React.Component {

	constructor(props) {
	    super(props);
      this.controller = new ScrollMagic.Controller();

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="App developer Pembrokeshire | Native and Web App"; 
    document.getElementsByTagName("META")[2].content="Native and PWA app developer in Pembrokeshire. Call me for a quote.";

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container row">
            
            <div className="g_grid_12 pageContent pageBanner">
              <main data-aos="fade-up">

                <p className="skull" data-aos="fade-down"><Skull/></p>

                <h1 className="textcenter">Native and Web<br /><span className="green">App</span> Development </h1>


                <div className="section"  data-aos="fade-up">
                  <h2>An <span className="green">app</span> can have a function or be entertaining</h2> 
                  <p>An app can be native to the device (mainly iOS and Android devices) or it can be a progressive web app. And as usual with these things, it depends on the application as to which you need. If you want to put your app in the app store Google play store you need a native app.</p>
                  <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some of my work</a></p>
                </div>
      
                <div className="section"  data-aos="fade-up">
                  <h2>Whats cooler than having your own <span className="green">app</span> in the app store?</h2>
                  <p>Being able to burp the national anthem! Thats pretty legendary.</p>
                  <p>So if you have an idea for an app which could be the next Angry Birds then use my web app to get in touch.</p>

                  <p><a href="contact-website-designer" className="button purpleGradBg rounded mt20">Want to talk apps?</a></p>
                </div>
              </main>

  
            </div>

          </section>

                  <div className="section" >
          <section className="container row">

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="graphic-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box blueBg"></div>
                  <div className="graphicDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="blue">Graphic</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="website-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box orangeBg"></div>
                  <div className="websiteDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="orange">Website</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="motion-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box pinkBg"></div>
                  <div className="motionDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="pink">Motion</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="app-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box greenBg"></div>
                  <div className="appIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="green">App</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="web-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box yellowBg"></div>
                  <div className="webDevIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="yellow">Website</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="white-label-design">

                <div className="serviceIcon">
                  <div className="serviceIcon-box purpleBg"></div>
                  <div className="whiteLabelIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="purple">White</span> Label</h3>
                </div>

              </a>
            </div>

          </section>

          <div className="section container small">
             <p>If you need a native app or web app developed then get on the blower.</p>
             <p>I'm an app developer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>

        </div>

        </article>
      </div>


    );

    
  }
  
}

export default Appdev;
