import React from 'react';
import { Component } from "react";
import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";

class Horizontalmenu extends Component {
  render() {

    return(

      <div className="header row">

          <div className="logo">
            <div className="logoName">
              <Link to="/">Dead Sea</Link>
            </div>
          </div>

          <nav id="nav" className="mainNav">
            <ul className="navigation row">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/graphic-design-pembrokeshire-portfolio" className="blue">Graphics</Link>
              </li>
              <li>
                <Link to="/illustration-design-portfolio" className="pink">Illustration</Link>
              </li>
              <li>
                <Link to="/website-design-pembrokeshire-portfolio" className="orange">Websites</Link>
              </li>
              <li>
                <Link to="/sustainable-working">Sustainability</Link>
              </li>
              <li className="navButton">
                <Link to="/contact-website-designer" className="rounded purpleGradBg">Get in Touch</Link>
              </li>
            </ul>
          </nav>

          <div className="menu-icon-set menuToggle ">
            <span></span>
            <span></span>
          </div>

        </div>

    )
  }
}

export default Horizontalmenu;