import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import worklist from "./data/work-data";
import { ReactComponent as Fish } from './images/fish-bones.svg';
var motion1 = require('./images/website-design-pembrokeshire.jpg');
const images = require.context('./images', true);

export class Motion extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Motion designer Pembrokeshire | Motion Design for web, TV and film"; 
    document.getElementsByTagName("META")[2].content="Get your web, TV or film project moving with some motion design. Contact us for a quote";

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container row">
            
            <div className="g_grid_12 pageContent">

              <main>

                <p className="fish" data-aos="fade-down"><Fish/></p>

                <h1 className="textcenter"><span className="pink">Motion</span> Design<br />and Animation</h1>

                <div className="videoWrapper ">
                    <iframe src="https://player.vimeo.com/video/432753340" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

                <caption>Pretty rad intro huh?</caption>

                <div className="section"  data-aos="fade-up">
                  <h2>Get your story told in motion.</h2> 
                  <p>Motion design can be used to tell a story or direct an action or suggest a path for a user. Animation is another word but if you want to be really cool you say 'motion design'. Unless your talking to your Grandad in which case just say 'cartoon'.
  "Its like a cartoon Grandad. Yes like Woody Woodpecker. Now pop your teeth in and eat your steak and kidney pie"</p>
                  <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some motion design work</a></p>
                </div>

                <div className="videoWrapper ">
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/402617950" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2 >What's the difference?</h2>
                  <p>Actually the difference between motion design and animation is a hotly debated topic so lets just say if you want something to move on your website or in your film or commercial then it can be done.</p>
                  <p>If you want to talk motion design for your project then get in touch.</p>
                  <p><a href="contact-website-designer" className="button purpleGradBg rounded mt20">Let's talk motion design</a></p>
                </div>

              </main>

            </div>

          </section>


          <div className="section container small">
             <p>If you need some fancy motion design or illustration then give me a shout and get your project 'moving'. Ha!. I know.</p>
             <p>I'm a website designer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>



        </article>
      </div>


    );

    
  }
  
}

export default Motion;
