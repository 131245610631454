import React from 'react';
import { Component } from "react";
import $ from 'jquery';

import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/skull-crossbones.svg';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

var traveller = require('./images/traveller-graphic-design.jpg');
const images = require.context('./images', true);

export class Portfolio extends React.Component {

	constructor(props) {
	    super(props);
      
      var id = 0;
      switch(window.location.pathname) {
        case '/brochure-graphic-design':
            id =0;
            document.title ="Graphic design Pembrokeshire | Graphic Design by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
         case '/scream-ice-cream-branding':
            id =1;
            document.title ="Logo Design Pembrokeshire | Branding and Identity Design"; 
            document.getElementsByTagName("META")[2].content="Logo and branding design, get noticed, be recognised. Contact us for a quote";
            break;
        case '/miconn-branding-design':
            id =2;
            document.title ="Logo Design Pembrokeshire | Branding and Identity Design"; 
            document.getElementsByTagName("META")[2].content="Logo and branding design, get noticed, be recognised. Contact us for a quote";
            break;
        case '/surf-school-flyer-design':
            id =3;
            document.title ="Graphic Design Pembrokeshire | Flyers, brochures, business cards Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/tattoo-shop-logo-design':
            id =4;
            document.title ="Logo Design Pembrokeshire | Flyers, brochures, business cards Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/teabreak-project-logo-design':
            id =5;
            document.title ="Logo Design Pembrokeshire | Branding and Identity Design"; 
            document.getElementsByTagName("META")[2].content="Logo and branding design, get noticed, be recognised. Contact us for a quote";
            break;
        case '/film-poster-design':
            id =6;
            document.title ="Poster Design Pembrokeshire | Graphic Design by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Poster, logos, brochures and branding design, get noticed, be recognised. Contact us for a quote";
            break;
         case '/beer-label-design':
            id =7;
            document.title ="Graphic design Pembrokeshire | Graphic Design by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/creative-common-logo-design':
            id =8;
            document.title ="Logo and Branding Design Pembrokeshire | Branding and Identity Design"; 
            document.getElementsByTagName("META")[2].content="Logo and branding design, get noticed, be recognised. Contact us for a quote";
            break;
        case '/see-the-world-illustration':
            id =9;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/stay-safe-motion-design':
            id =10;
            document.title ="Motion design Pembrokeshire | Motion Design for web, social media, TV and film"; 
            document.getElementsByTagName("META")[2].content="Get your project moving with some motion by Dead Sea Design. Contact us for a quote";
            break;
        case '/end-of-summer-illustration':
            id =11;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/barista-illustration':
            id =12;
            document.title ="Graphic design Pembrokeshire | Graphic Design by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/dude-illustration':
            id =13;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/dead-fish-illustration':
            id =14;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/salty-seadog-illustration':
            id =15;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/sweetshop-illustration':
            id =16;
            document.title ="Illustration and design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/tin-hats-illustration':
            id =17;
            document.title ="Illustration and motion design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/whales-illustration':
            id =18;
            document.title ="Illustration and motion design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/octopus-illustration':
            id =19;
            document.title ="Illustration and motion design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/pcc-motion-illustration':
            id =20;
            document.title ="Illustration and motion design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
        case '/astro-panda-illustration':
            id =21;
            document.title ="Illustration and motion design Pembrokeshire | Illustration by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;

        case '/preseli-glamping-website-development':
            id =22;
            document.title ="Website designer Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        case '/atlantic-edge-oysters-website-design':
            id =23;
            document.title ="Website design Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
         case '/gorilla-website-design':
            id =24;
            document.title ="Website design Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        case '/neurons-website-design':
            id =25;
            document.title ="Website designer Pembrokeshire | Website design and development by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        case '/st-davids-escapes-website-design':
            id =26;
            document.title ="Website designer Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        case '/sunny-recruitment-website-design':
            id =27;
            document.title ="Website design Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        case '/jarem-accountants-website-design':
            id =28;
            document.title ="App Developer Pembrokeshire | Native and PWA development"; 
            document.getElementsByTagName("META")[2].content="App development for all devices. Make your app idea a reality. Contact us for a quote";
            break;
        case '/logis-de-canteau-website-design':
            id =29;
            document.title ="App Developer Pembrokeshire | Native and PWA development"; 
            document.getElementsByTagName("META")[2].content="App development for all devices. Make your app idea a reality. Contact us for a quote";
            break;
        case '/milkwood-project-website-design':
            id =30;
            document.title ="Website design Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, app development, graphic design. Contact us for a quote";
            break;
        
 

      }

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: [],
	      check: 'check',
        id: id
	    };

      this.filter = this.filter.bind(this);
	}

	componentDidMount() {
        window.scrollTo(0, 0);

  }

  filter(e) {

  }

  render() {

    return (
      <div>


            <div className="has-left-menu darkBlueBg portoliomainwrap">
              <main data-aos="fade-up" className="portfolioContent">

                <div className="skull">
                  <Skull/>
                </div>

                <h1 className="portfolioTitle textcenter">{worklist[this.state.id].title}</h1>
                <p className="lead textcenter"><strong>{worklist[this.state.id].desc1}</strong></p>

                <div className={`videoWrapper pageVideo ${worklist[this.state.id].video ? "" : "hidden"}`} >
                    <iframe src={`${worklist[this.state.id].video}`}frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

                <img src={`${worklist[this.state.id].banner}`} alt={worklist[this.state.id].title} className={` firstImg ${worklist[this.state.id].banner ? "" : "hidden"}`} />
            
    
                <p className="lead">{worklist[this.state.id].desc2}</p>

                <p>{worklist[this.state.id].desc3}</p>

                <div className="portfolioIconSection">
                <div className="portfolioItem grad " data-aos="fade-up">

                  <div className="portfolioIcon-details row">
                    <div className="row">
                      <div className="g_grid_8">
                        <blockquote>
                          {worklist[this.state.id].testimonial}
                        </blockquote>
                      </div>
                      <div className="g_grid_4 omega row portfolioItem-icons">
                        <div className={`websiteDesignIcon miniIcon ${worklist[this.state.id].skills.websitedesign == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}></div>
                        <div className={`graphicDesignIcon miniIcon ${worklist[this.state.id].skills.graphicdesign == true || worklist[this.state.id].skills.illustration == true || worklist[this.state.id].skills.logodesign == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}></div>
                        <div className={`webDevIcon miniIcon ${worklist[this.state.id].skills.webdevelopment == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}></div>
                        <div className={`motionDesignIcon miniIcon ${worklist[this.state.id].skills.motiondesign == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}></div>
                        <div className={`appIcon miniIcon ${worklist[this.state.id].skills.appdevelopment == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}></div>
                      </div>
                    </div>
                    <p>
                      <span className={`orange ${worklist[this.state.id].skills.websitedesign == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Website design </span>
                      <span className={`blue ${worklist[this.state.id].skills.graphicdesign ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Graphic Design </span>
                      <span className={`pink ${worklist[this.state.id].skills.motiondesign ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Motion Design </span>
                      <span className={`blue ${worklist[this.state.id].skills.illustration == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Illustration </span> 
                      <span className={`yellow ${worklist[this.state.id].skills.webdevelopment ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Website Development </span>
                      <span className={`green ${worklist[this.state.id].skills.appdevelopment ? "active animate__animated animate__fadeInDown" : "hidden"}`}> App Development </span> 
                    </p>
                  </div>
                </div>
              </div>

              <div className="secondaryContent">
                <p>{worklist[this.state.id].desc4}</p>

                <img src={`${worklist[this.state.id].image2}`} alt={worklist[this.state.id].title} className={`  secondImg ${worklist[this.state.id].image2 ? "" : "hidden"}`} />

              </div>
              <div className="secondaryContent">

                <img src={`${worklist[this.state.id].image3}`} alt={worklist[this.state.id].title} className={` secondImg ${worklist[this.state.id].image3 ? "" : "hidden"}`} />

              </div>

              

              </main>

              
  
            </div>


      </div>


    );

    
  }
  
}

export default Portfolio;
