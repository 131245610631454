import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import ScrollMagic from "scrollmagic";
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/skull-crossbones.svg';
import { ReactComponent as Leaf } from './images/icons/leaf.svg';
import { ReactComponent as Water } from './images/icons/droplet.svg';
import { ReactComponent as Hand } from './images/icons/hand.svg';
import { ReactComponent as Heart } from './images/icons/heart.svg';
var tshirt = require('./images/products/mauritius-oil-spill-t-shirt.png');
const images = require.context('./images', true);

export class Product extends React.Component {

	constructor(props) {
	    super(props);
      this.controller = new ScrollMagic.Controller();

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Organic T-Shirt to raise funds for the Mauritius Oil Spill | Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Eco-Sud will use the funds to help clean up the oil spill and then use any remaining money for post-crisis projects";

  }


  render() {

    return (
      <div>

       <article className="" >
        <div className='container product-container'>
         

          <header>
                <p className="pre-title"><span className="orange">Fundraiser</span> for the Mauritius Oil Spill</p>
                <h1 className="product-title">Organic Cotton, Printed 'Turtle' T-Shirt</h1>
              </header>

          <div className=" row">

            <section className="product-description g_grid_4 forceright">

              <p className="product-price green">&pound;20.00</p>
              <p className="small product-price-terms">Price per t-shirt</p>
              <p>We've teamed up with those legends at <a href="https://www.westcoastsurfwax.co.uk" target="_blank">West Coast Surf Wax</a> to raise some dosh towards clearing up the oil which is currently darkening the clear waters off the coast of Mauritius.</p>
              <p><a href="#more" className="more">More info &raquo;</a></p>
              <p>
              <label className="instruct">Select a size (you will be redirected to checkout)</label>

              <button
                className="checkout-btn purpleGradBg"
                id="checkout-button-price_0HH4XJjWF3zgB37bNcD2n191"
                role="link"
                type="button"
                data-id="price_0HH4XJjWF3zgB37bNcD2n191"
              >
                Small
              </button> 
              <button
                className="checkout-btn purpleGradBg"
                id="checkout-button-price_0HH4X2jWF3zgB37b1y4qnGCa"
                role="link"
                type="button"
                data-id="price_0HH4X2jWF3zgB37b1y4qnGCa"
              >
                Medium
              </button>
              <button
                className="checkout-btn purpleGradBg"
                id="checkout-button-price_0HH4X8jWF3zgB37bYz5jF1Oz"
                role="link"
                type="button"
                data-id="price_0HH4X8jWF3zgB37bYz5jF1Oz"
              >
                Large
              </button>
              <button
                className="checkout-btn purpleGradBg"
                id="checkout-button-price_0HH4XEjWF3zgB37b1wAkZxiT"
                role="link"
                type="button"
                data-id="price_0HH4XEjWF3zgB37b1wAkZxiT"
              >
                XLarge
              </button>
              </p>

              <div id="error-message"></div>

            </section>

            <section className="product-image g_grid_6 forceright textcenter">
              <figure>
                <img src={tshirt} alt="Organic cotton t-shirt" className=" " data-aos="fade-up" />
                 <p className="dead-sea-para"><Skull/> A Dead Sea Product</p>
              </figure>
            </section>

            <section className="product-highlights g_grid_2">
              <ul>
                <li><span className="green"><Leaf /></span>100% Organic Cotton Shirt</li>
                <li><span className="blue"><Water /></span>Printed with Water Based Ink</li>
                <li><span className="purple"><Hand /></span>Hand Printed in Pembrokeshire</li>
                <li><span className="pink"><Heart /></span>£10 From Every Shirt Donated to Eco-Sud</li>
              </ul>
            </section>
          </div>
        </div>

        <div className="section" id="more">
          <section className="container">
            <header  data-aos="fade-up">
              <h2>What the heckers like are we raising money for?</h2>
            </header>
            <main data-aos="fade-up">
              <p>On the 25th July 2020, the Merchant Vessel Wakashio grounded on coral reefs off the coast of the tropical island, Mauritius.</p>
              <p>More than 1000 tonnes of fuel oil leaked from the ship into the clear, blue waters of the Indian Ocean.</p>
              <p>It's difficult to predict how severe the impact will be on the marine environment so the cost to clean up and rehabilitate the coast is still uncertain.</p>
              <p>Eco-Sud is a local non-profit organisation taking on the challenge and any profits from this product will be donated to help their cause.</p>
              <p>Eco-Sud will use the funds to help clean up the oil spill and then use any remaining money for post-crisis projects such as studies about the effects of the oil spill, conservation projects, potential legal action, and more.</p>
              <p>Find out more about them at <a href="http://ecosud.mu" target="_blank">ecosud.mu</a></p>
              <p>£10 From every one of these t-shirts sold will be donated to Eco-Sud.</p>
            </main>

            <div className="section small">
             <p>Please allow 14 days for delivery of this product as we print on demand just for you. I know, you're welcome!</p>
             <p>Delivery is included to the UK. If you are outside the UK please get in touch for a shipping cost.</p>
          </div>
        
          </section>
        </div>


        <div className="section" >
          <section className="container row">

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="graphic-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box blueBg"></div>
                  <div className="graphicDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="blue">Graphic</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="website-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box orangeBg"></div>
                  <div className="websiteDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="orange">Website</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="motion-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box pinkBg"></div>
                  <div className="motionDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="pink">Motion</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="app-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box greenBg"></div>
                  <div className="appIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="green">App</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="web-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box yellowBg"></div>
                  <div className="webDevIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="yellow">Website</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="white-label-design">

                <div className="serviceIcon">
                  <div className="serviceIcon-box purpleBg"></div>
                  <div className="whiteLabelIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="purple">White</span> Label</h3>
                </div>

              </a>
            </div>

          </section>

          <div className="section container small">
             <p>If you need <span className="blue">graphic design</span> for your t-shirts, company brochures or business cards then give me a shout.</p>
             <p>I'm a graphic designer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>

        </div>

      </article>
    </div>


    );

    
  }
  
}

export default Product;
