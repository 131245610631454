import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/graphic-design-pad.svg';

var sweetshop = require('./images/sweetshop-illustration.jpg');
var traveller = require('./images/traveller-graphic-design.jpg');
var newsurf = require('./images/newsurf-graphic-design.jpg');
const images = require.context('./images', true);

export class Illustration extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Graphic design Pembrokeshire | Graphic Designer, Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";

  }


  render() {

    return (
      <div>

      <div className="banner pageBanner">
          <div className="container textcenter section">
            <p className="skull" data-aos="fade-down"><Skull/></p>

            <h1 className="textcenter"><span className="pink">Illustration</span> and Design </h1>
            <p>From character design, to icons to books. We love to create colourful ideas for all types of projects.</p>
          </div>
        </div>

       <article className="" >
          <section className="row">
            
            <div className="g_grid_12 ">
              <main data-aos="fade-up">

                <div className="container">
                  <img src={sweetshop} alt="Graphic designer" className="rounded " data-aos="fade-up" />
                  <caption>Design and illustration for a sweet shop. Yummy!</caption>
                </div>

                <div className="section container is-style-narrow"  data-aos="fade-up">
                  <h2>Illustrative design for brands that want to stand out </h2> 
                  <p>From unique icons to brighten up your packaging to full character design. We can be your creative partner to acheive your illustrated goal.</p>
                  <p><a href="illustration-design-portfolio" className="button purpleGradBg rounded mt20">Some illustration work</a></p>
                </div>
      
                <div className="container">
                  <img src={traveller} alt="Graphic designer" className="rounded  " data-aos="fade-up" />
                  <caption>Graphic design and illustration for a beer bottle label. Burp!</caption>
                </div>
                
              </main>

  
            </div>

          </section>

         
                  

          <div className="section container small">
             <p>If you need <span className="pink">illustration</span> for your project or business then give me a shout.</p>
             <p>I'm an illustrator in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>

 

        </article>
      </div>


    );

    
  }
  
}

export default Illustration;
