import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/graphic-design-pad.svg';

var sweetshop = require('./images/molly-greens-wall-sign.jpg');
var traveller = require('./images/celtic-holidays-brochure-design-2.jpg');
var newsurf = require('./images/newsurf-graphic-design.jpg');
const images = require.context('./images', true);

export class Graphic extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Graphic design Pembrokeshire | Graphic Designer, Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";

  }


  render() {

    return (
      <div>

      <div className="banner pageBanner">
          <div className="container textcenter section">
            <p className="skull" data-aos="fade-down"><Skull/></p>

            <h1 className="textcenter"><span className="blue">Graphic</span> Design<br />and Illustration </h1>
            <p>Graphic design is a way to visually communicate or problem solve using imagery, typography and illustration.  </p>
          </div>
        </div>

       <article className="" >
          <section className="row">
            
            <div className="g_grid_12 ">
              <main data-aos="fade-up">

                <div className="container">
                  <img src={sweetshop} alt="Graphic designer" className="rounded " data-aos="fade-up" />
                  <caption>Design and illustration for a sweet shop. Yummy!</caption>
                </div>

                <div className="section container is-style-narrow"  data-aos="fade-up">
                  <h2>Awesome <span className="blue">design</span> and illustration for any project</h2> 
                  <p>'They' say graphic design is a way to visually communicate or problem solve using imagery, typography and illustration. However you want to break it down, if you need graphic design for your brochures, leaflets, business cards or t-shirt design, give me a yell. </p>
                  <p><a href="graphic-design-pembrokeshire-portfolio" className="button purpleGradBg rounded mt20">Some graphic design work</a></p>
                </div>
      
                <div className="container">
                  <img src={traveller} alt="Graphic designer" className="rounded  " data-aos="fade-up" />
                  <caption>Graphic design and illustration for a beer bottle label. Burp!</caption>
                </div>
                <div className="section container"  data-aos="fade-up">
                  <h2><span className="blue">Graphic design</span> is everywhere on your telly box, in film and TV</h2>
                  <p>Although we never really notice it (if it's good we shouldnt notice it). Things in the background like posters or signs are done by a graphic designer. So when the hero saves the baboon from the burning paper mill and there is the poignant shot of the newspaper with the burnt edges, that newspaper was done by a graphic designer.</p>

                  <p>You can see some of my work in some great independent films like <a href="https://www.imdb.com/title/tt5239086/" target="_blank">London Recruits</a> and <a href="https://www.imdb.com/title/tt9564860" target="_blank">La Petite Mort</a>.</p>

                  <p><a href="contact-website-designer" className="button purpleGradBg rounded mt20">Need some graphic design?</a></p>
                </div>

                <div className="container">
                  <img src={newsurf} alt="Graphic designer" className="rounded " data-aos="fade-up" />
                  <caption>T-shirt design for those dudes down at Newsurf Surf Shop</caption>
                </div>
              </main>

  
            </div>

          </section>

         
                  

          <div className="section container small">
             <p>If you need <span className="blue">graphic design</span> for your film, Television programme or your company brochures and business cards then give me a shout.</p>
             <p>I'm a graphic designer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>

 

        </article>
      </div>


    );

    
  }
  
}

export default Graphic;
