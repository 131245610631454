import React from 'react';
import { Component } from "react";
import Services from './inc/Serviceicons';
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/trees.svg';

const images = require.context('./images', true);

export class Sustainable extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Sustainable design and development from Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="We believe we should all do our part to keep things nice. And things ARE nice...right?";

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container row is-style-narrow">
            
            <div className="g_grid_12 pageContent">
              <main data-aos="fade-up">

                <p className="skull" data-aos="fade-down"><Skull/></p>

                <h1 className="textcenter"><span className=""> Sustainability</span><br />and Responsibility </h1>

                <div className="section"  data-aos="fade-up">
                  <h3>We all have a responsibility to do our part for the environment</h3> 
                  <p>We recognise that our environmental responsibility should go beyond our legal and regulatory obligations so we are committed to reducing our environmental impact. We will continually improve our environmental performance as an integral part of our business strategy and operating methods. We will also encourage our customers and suppliers to do the same.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Policy</span> Goals</h2>
                  <p>We endeavour to comply with all relevant regulatory requirements as we hope we can go beyond the expected level in some cases. We will monitor and improve when possible, our environmental impact and use that impact when making business decisions i.e. if it's gonna burn down a forest to make a few quid, we ain't gonna do it.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Paper</span> and Printing</h2>
                  <p>We will minimise the use of paper in the office by not printing emails and memes (we don't even have an office printer). We will reduce packaging as much as possible. We will seek to buy recycled and recyclable paper products when buying is absolutely necessary. We will reuse and recycle all paper where possible, reuse first and recycle last.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Energy</span> and Water</h2>
                  <p>We will seek to reduce the amount of energy used as much as possible. Lights and electrical equipment will be switched off when not in use. Heating will be adjusted with energy consumption in mind. The energy consumption and efficiency of new products will be taken into account when purchasing. Our electric is supplied by a company producing it from 100% renewable sources.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Office</span> Supplies</h2>
                  <p>We will evaluate if the need can be met in another way. We will evaluate if renting/sharing is an option before purchasing equipment. We will evaluate the environmental impact of any new products we intend to purchase. We will favour more environmentally friendly and efficient products wherever possible. We will reuse and recycle everything we are able to, reusing first and recycling second.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Transportation</span></h2>
                  <p>We will reduce the need to travel, restricting to necessary trips only. We will promote the use of travel alternatives such as e-mail or video/phone conferencing, who doesn't love a Zoom call?</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Maintenance</span> and Cleaning</h2>
                  <p>Cleaning materials used will be as environmentally friendly as possible, we try to keep cleaning to a minimum anyway, what a rubbish job! We will recycle as much of our waste as possible.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Monitering</span> and Improvement</h2>
                  <p>We will comply with and try to exceed all relevant regulatory requirements. We will continually improve and monitor environmental performance. We will continually improve and reduce environmental impacts. We will consider environmental factors in business decisions. We will increase awareness by talking to our customers and suppliers. We will update this policy with any changes to the business.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Reducing</span> Our CO<sup>2</sup> Footprint</h2>
                  <p>We subscribe to <a href="mosssy.earth" target="_blank">Mossy Earth</a> to offset the carbon emissions we can't avoid. Through rewilding, Mossy Earth are helping to restore and improve natural ecosystems throughout the world.</p>
                </div>

                <div className="section"  data-aos="fade-up">
                  <h2><span className="green">Culture</span></h2>
                  <p>We live this life, reducing our impact as much as we can day to day.</p>
                </div>

              </main>

  
            </div>

          </section>

          <Services />

                  
        </article>
      </div>


    );

    
  }
  
}

export default Sustainable;
