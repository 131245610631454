import React from 'react';
import { Component } from "react";
import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";
import Horizontalmenu from './inc/Horizontalmenu';
import Verticalmenu from './inc/Verticalmenu';
import Home from './Home';
import Work from './Work';
import Portfolio from './Portfolio';
import Graphic from './Graphic';
import Illustration from './Illustration';
import Appdev from './Appdev';
import Motion from './Motion';
import Whitelabel from './Whitelabel';
import Website from './Website';
import Contact from './Contact';
import Faq from './Faq';
import Product from './Product';
import Sustainable from './Sustainability';

import Websiteseo from './Websiteseo';
import Graphicseo from './Graphicseo';

function App() {
  return (
     <BrowserRouter>


          <Switch>

            <Route path="/illustration-portfolio">
              <Verticalmenu />
              <Work />
            </Route>
            <Route path="/design-portfolio-pembrokeshire">
              <Verticalmenu />
              <Work />
            </Route>
            <Route path="/illustration-design-portfolio">
              <Verticalmenu />
              <Work />
            </Route>

            <Route path="/website-design-pembrokeshire-portfolio">
              <Verticalmenu />
              <Work />
            </Route>
            <Route path="/motion-design-pembrokeshire-portfolio">
              <Verticalmenu />
              <Work />
            </Route>
            <Route path="/graphic-design-pembrokeshire-portfolio">
              <Verticalmenu />
              <Work />
            </Route>
            <Route path="/logo-design-pembrokeshire-portfolio">
              <Verticalmenu />
              <Work />
            </Route>

            <Route path="/website-design-portfolio-pembrokeshire">
              <Redirect from="/website-design-portfolio-pembrokeshire" to="/website-design-pembrokeshire-portfolio" />
            </Route>
            <Route path="/illustration-design-portfolio-pembrokeshire">
              <Redirect from="/illustration-design-portfolio-pembrokeshire" to="/illustration-design-portfolio" />
            </Route>
            <Route path="/motion-design-portfolio-pembrokeshire">
              <Redirect from="/motion-design-portfolio-pembrokeshire" to="/motion-design-pembrokeshire-portfolio" />
            </Route>
            <Route path="/graphic-design-portfolio-pembrokeshire">
              <Redirect from="/graphic-design-portfolio-pembrokeshire" to="/graphic-design-pembrokeshire-portfolio" />
            </Route>
            <Route path="/app-development-portfolio-pembrokeshire">
              <Redirect from="/app-development-portfolio-pembrokeshire" to="/app-development-pembrokeshire-portfolio" />
            </Route>
            <Route path="/web-development-portfolio-pembrokeshire">
              <Redirect from="/web-development-portfolio-pembrokeshire" to="/web-development-pembrokeshire-portfolio" />
            </Route>
            <Route path="/web-development-pembrokeshire-portfolio">
              <Redirect from="/web-development-pembrokeshire-portfolio" to="/website-design-pembrokeshire-portfolio" />
            </Route>
            <Route path="/app-development-pembrokeshire-portfolio">
              <Redirect from="/app-development-pembrokeshire-portfolio" to="/website-design-pembrokeshire-portfolio" />
            </Route>

            <Route path="/beer-label-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/barista-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/end-of-summer-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/stay-safe-motion-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/see-the-world-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/creative-common-logo-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/dead-fish-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/dude-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/salty-seadog-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/sweetshop-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/tin-hats-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/whales-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/octopus-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/pcc-motion-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/astro-panda-illustration">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/miconn-branding-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/surf-school-flyer-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/tattoo-shop-logo-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/teabreak-project-logo-design">
             <Verticalmenu />
             <Portfolio />
            </Route>
            <Route path="/film-poster-design">
              <Verticalmenu />
              <Portfolio />
            </Route>

            <Route path="/brochure-graphic-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/preseli-glamping-website-development">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/neurons-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            
            <Route path="/scream-ice-cream-branding">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/gorilla-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/atlantic-edge-oysters-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/st-davids-escapes-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/sunny-recruitment-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/jarem-accountants-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/logis-de-canteau-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            <Route path="/milkwood-project-website-design">
              <Verticalmenu />
              <Portfolio />
            </Route>
            

            <Route path="/contact-website-designer">
              <Horizontalmenu />
              <Contact />
            </Route>
            <Route path="/sustainable-working">
              <Horizontalmenu />
              <Sustainable />
            </Route>
            <Route path="/graphic-designer-pembrokeshire">
              <Horizontalmenu />
              <Graphic />
            </Route>
            <Route path="/illustrator-pembrokeshire">
              <Horizontalmenu />
              <Illustration />
            </Route>
            <Route path="/website-designer-pembrokeshire">
              <Horizontalmenu />
              <Website />
            </Route>
            <Route path="/web-development-pembrokeshire">
              <Horizontalmenu />
              <Website />
            </Route>
            <Route path="/motion-designer-pembrokeshire">
              <Horizontalmenu />
              <Motion />
            </Route>
            <Route path="/app-development-pembrokeshire">
              <Horizontalmenu />
              <Appdev />
            </Route>
            <Route path="/white-label-design">
              <Horizontalmenu />
              <Whitelabel />
            </Route>
            <Route path="/website-design-faq">
              <Horizontalmenu />
              <Faq />
            </Route>

            <Route path="/website-design-pembrokeshire">
              <Horizontalmenu />
              <Websiteseo />
            </Route>

            <Route path="/graphic-design-pembrokeshire">
              <Horizontalmenu />
              <Graphicseo />
            </Route>

            <Route path="/">
              <Horizontalmenu />
              <Home />
            </Route>
          </Switch>


        <footer className="footer">
          <div className="container row">
            <div className="g_grid_7">
              <p><a href="/" className="footer-logo">Dead Sea Design</a></p>
              <p>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <title>bullhorn</title>
                <path d="M32 13.414c0-6.279-1.837-11.373-4.109-11.413 0.009-0 0.018-0.001 0.027-0.001h-2.592c0 0-6.088 4.573-14.851 6.367-0.268 1.415-0.438 3.102-0.438 5.047s0.171 3.631 0.438 5.047c8.763 1.794 14.851 6.367 14.851 6.367h2.592c-0.009 0-0.018-0.001-0.027-0.001 2.272-0.040 4.109-5.134 4.109-11.413zM27.026 23.102c-0.293 0-0.61-0.304-0.773-0.486-0.395-0.439-0.775-1.124-1.1-1.979-0.727-1.913-1.127-4.478-1.127-7.223s0.4-5.309 1.127-7.223c0.325-0.855 0.705-1.54 1.1-1.979 0.163-0.182 0.48-0.486 0.773-0.486s0.61 0.304 0.773 0.486c0.395 0.439 0.775 1.124 1.1 1.979 0.727 1.913 1.127 4.479 1.127 7.223s-0.4 5.309-1.127 7.223c-0.325 0.855-0.705 1.54-1.1 1.979-0.163 0.181-0.48 0.486-0.773 0.486zM7.869 13.414c0-1.623 0.119-3.201 0.345-4.659-1.48 0.205-2.779 0.323-4.386 0.323-2.096 0-2.096 0-2.096 0l-1.733 2.959v2.755l1.733 2.959c0 0 0 0 2.096 0 1.606 0 2.905 0.118 4.386 0.323-0.226-1.458-0.345-3.036-0.345-4.659zM11.505 20.068l-4-0.766 2.558 10.048c0.132 0.52 0.648 0.782 1.146 0.583l3.705-1.483c0.498-0.199 0.698-0.749 0.444-1.221l-3.853-7.161zM27.026 17.148c-0.113 0-0.235-0.117-0.298-0.187-0.152-0.169-0.299-0.433-0.424-0.763-0.28-0.738-0.434-1.726-0.434-2.784s0.154-2.046 0.434-2.784c0.125-0.33 0.272-0.593 0.424-0.763 0.063-0.070 0.185-0.187 0.298-0.187s0.235 0.117 0.298 0.187c0.152 0.169 0.299 0.433 0.424 0.763 0.28 0.737 0.434 1.726 0.434 2.784s-0.154 2.046-0.434 2.784c-0.125 0.33-0.272 0.593-0.424 0.763-0.063 0.070-0.185 0.187-0.298 0.187z"></path>
                </svg>

              <a href="tel:01437 723196">01437 723196</a></p>
              <p>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <title>paper-plane-o</title>
                <path d="M27.563 0.172c0.328 0.234 0.484 0.609 0.422 1l-4 24c-0.047 0.297-0.234 0.547-0.5 0.703-0.141 0.078-0.313 0.125-0.484 0.125-0.125 0-0.25-0.031-0.375-0.078l-8.234-3.359-4.656 5.109c-0.187 0.219-0.453 0.328-0.734 0.328-0.125 0-0.25-0.016-0.359-0.063-0.391-0.156-0.641-0.531-0.641-0.938v-7.063l-7.375-3.016c-0.359-0.141-0.594-0.469-0.625-0.859-0.031-0.375 0.172-0.734 0.5-0.922l26-15c0.328-0.203 0.75-0.187 1.062 0.031zM22.219 23.594l3.453-20.672-22.406 12.922 5.25 2.141 13.484-9.984-7.469 12.453z"></path>
                </svg>
                <a href="mailto:hello@deadseadesign.co.uk">hello@deadseadesign.co.uk</a></p>

              <p className="address">Unit 6, Hilton Court Gardens, Roch, Haverfordwest SA62 6AE</p>
            </div>
            <div className="g_grid_5 footerRight">
              <a href="contact-website-designer" className="button purpleGradBg rounded">Get a Quote</a>
              <ul>
                <li><a href="website-design-faq">FAQ</a></li>
                <li><a href="contact-website-designer">Contact Us</a></li>
              </ul>

              <p className="footer-desc">Dead Sea Design Ltd. Digital illustrator, <a href="website-designer-pembrokeshire">Website designer</a>, <a href="graphic-designer-pembrokeshire">graphic designer</a> and <a href="web-development-pembrokeshire">developer</a> based in Pembrokeshire. Made by the sea using React, CSS, a few bits of magic.</p>
            </div>

          </div>
          <div className="container mainAddress textcenter">
            Dead Sea Design Ltd. Reg Office: Hamilton House, Hamilton Terrace, Milford Haven, Pembrokeshire SA73 3JP<br />
            Company Number: 13343558
          </div>
        </footer>
     
      </BrowserRouter>
  );
}

export default App;
