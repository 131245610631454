export default [

  {
    title: "Brochure Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false
    },
    testimonial : "Ooh I think I might go on my holidays here, it looks grand!",
    link: "/brochure-graphic-design",
    image: "./images/work/celtic-holidays-brochure-design.jpg",
    banner: "./images/work/celtic-holidays-brochure-design-2.jpg",
    desc1: "There's nothing like thumbing through a real live brochure",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "./images/work/celtic-holidays-brochure-design.jpg",
    image3: ""
  },
  {
    title: "Scream Ice Cream Branding",
    seotitle: "logodesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: true
    },
    testimonial: "You can't buy happiness, but you can buy ice cream",
    link: "/scream-ice-cream-branding",
    image: "./images/work/scream-ice-cream-logo-design.jpg",
    banner: "./images/work/scream-ice-cream-branding.jpg",
    desc1: "A brand is more than a logo design. Scream deal in the best commodity on the planet, ice cream, so their brand had to do it justice.",
    desc2: "As always when creating a brand and designing a logo the client was given options on design, layout and colours and then worked up bit by bit to create the final elements.",
    desc3: "I love creating logos and I also love eating ice cream. I'm not sure which I like more... Who am I kidding? I like ice cream a lot more.",
    desc4: "If you have a project where you need someone to eat ice cream give me a call. If you have a logo that needs designing you might have to wait until I've finished my ice cream.",
    image2: "./images/work/scream-ice-cream-branding-stack.jpg",
    image3: ""
  },

  {
    title: "Miconn Branding Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: true
    },
    testimonial: "Top job from Dead Sea Design, nearly as good as the job we do",
    link: "/miconn-branding-design",
    image: "./images/work/miconn-logo-design.jpg",
    banner: "./images/work/miconn-logo-design.jpg",
    desc1: "Get your pipes sorted by MiConn ",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "./images/work/miconn-branding-design.jpg",
    image3: ""
  },

  {
    title: "Surf School Flyer Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: false
    },
    testimonial: "Yea, let's get out there and get barrelled man!",
    link: "/surf-school-flyer-design",
    image: "./images/work/surfing-flyer-design.jpg",
    banner: "./images/work/surfing-flyer-design-2.jpg",
    desc1: "Get in the water and have some fun",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "Tattoo Shop Logo Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: true
    },
    testimonial : "Great quality work to the highest standard and a quick turn around. I will definitely be using him again.",
    link: "/tattoo-shop-logo-design",
    image: "./images/work/tattoo-shop-sign-design.jpg",
    banner: "./images/work/tattoo-shop-sign-design.jpg",
    desc1: "Go get some ink on down at Molly Greens",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "./images/work/molly-greens-wall-sign.jpg",
    image3: ""
  },
  {
    title: "The Teabreak Project Logo Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: true
    },
    link: "/teabreak-project-logo-design",
    image: "./images/work/teabreak-project-envelope-square.jpg",
    banner: "./images/work/teabreak-project-logo-design.jpg",
    desc1: "What do you do if you're feeling creative but don't have any pasta to make a picture? You subscribe to The Teabreak Project!",
    desc2: "The Teabreak Project needed a brand mark and a logo so they stick it all over their marketing collateral (big word alert!). ",
    desc3: "They got a brand mark and logo in a choice of colours so they can change it around as much as their underwear. If they really want to.",
    desc4: "If you need a logo design or brand design or just...something...designed. Give me a yell.",
    image2: "./images/work/teabreak-project-logo-design.jpg",
    image3: "./images/work/teabreak-logo-design-colours.jpg"
  },
  {
    title: "Le Petite Mort Film Poster Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:false,
        graphicdesign:true,
        motiondesign:false,
        logodesign: false
    },
    testimonial : "The film went really well at the festivals, so did the poster",
    link: "/film-poster-design",
    image: "./images/work/le-petite-mort-poster-design.jpg",
    banner: "./images/work/le-petite-mort-poster-design-2.jpg",
    desc1: "At a cinema near you",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "./images/work/le-petite-mort-poster-design-3.jpg",
    image3: ""
  },
{
    title: "Beer Label Design",
    seotitle: "graphicdesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:true,
        motiondesign:false
    },
    testimonial: "It looks amazing and I think the label made the beer taste better",
    cite: "The Creative Common",
    link: "/beer-label-design",
    image: "./images/work/traveller-graphic-design.jpg",
    banner: "./images/work/traveller-graphic-design.jpg",
    desc1: "The thing about beer is, well it's beer isn't it?!",
    desc2: "When I got the opportunity to create a cool beer label made by an amazing brewer (The First and Last Brewery) for a rad coffee shop (The Creative Common) I said 'hell yea'.",
    desc3: "So what says beer, refreshing, flavour and adventure? A fricking skeleton cruising the highway on a hog that's what.",
    desc4: "One illustration of a dead guy burnin' some rubber and lots of tasting sessions later, we have one beer label design. Pop that in your fridge and drink it.",
    image2: "./images/work/traveller-graphic-design-full.jpg",
    image3: "./images/work/beer-bottle-design.jpg"
  },
  {
    title: "Brand and Logo Design",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:true,
        motiondesign:false
    },
    testimonial : "You did a really great job and really captured what we do here",
    link: "/creative-common-logo-design",
    image: "./images/work/cc-bee-illustration.jpg",
    banner: "./images/work/bee-illustration.jpg",
    desc1: "The Creative Common was the coolest coffee shop in town, so it needed a cool brand...",
    desc2: "From the shop signs to the menus to the stickers to the aprons, I designed it all. ",
    desc3: "And the coffee was damn fine too.",
    desc4: "",
    image2: "./images/work/creative-common-branding-design.jpg",
    image3: "./images/work/creative-common-logo-design.jpg"
  },
  {
    title: "See the World Poster Campaign",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "What do you mean I can't see the actual world with my phone? What about Google?",
    link: "/see-the-world-illustration",
    image: "./images/work/see-the-world-billboard.jpg",
    banner: "./images/work/see-the-world-illustration.jpg",
    desc1: "Sometimes phones really piss me off and the only way to address it is with a bit of sarcasm",
    desc2: "If you see one of these pasted to a lamp post or Phones 4 U window it wasn't me..right!",
    desc3: "",
    desc4: "",
    image2: "./images/work/see-the-world-poster.jpg",
    image3: ""
  },

  
  {
    title: "Stay Safe Promo",
    seotitle: "motiondesign",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:true
    },
    testimonial : "This is the sweetest thing I've seen since lockdown started",
    link: "/stay-safe-motion-design",
    video: "https://player.vimeo.com/video/402617950",
    image: "./images/work/stay-safe-promo-illustration.jpg",
    banner: "",
    desc1: "Motion design is a fancy name for designing stuff that moves. You could call it animation if you like but it doesnt sound as cool.",
    desc2: "There are loads of reasons why we use motion design. It could be to make something more noticeable, to tell a story or just so it looks nice.",
    desc3: "Motion designs or animations can be short, long, silent, with sound or used for action triggers. Get in touch if you need a motion designer and we can talk turkey.",
    desc4: "FACT BOMB: That's my voice you can hear on the film. Boom!",
    image2: "",
    image3: ""
  },

  {
    title: "End of Summer Surf Dude",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "What an amzing night! I love my t-shirt I just wish I could grow a beard like him",
    link: "/end-of-summer-illustration",
    image: "./images/work/end-of-summer-illustration.jpg",
    banner: "./images/work/end-of-summer-poster.jpg",
    desc1: "The Pembrokeshire surf community got together to organise a well deserved summer party for the locals.",
    desc2: "This surf dude was the face of the event, appearing on posters, t-shirts and the big festival screen.",
    desc3: "",
    desc4: "",
    image2: "./images/work/end-of-summer-illustration.jpg",
    image3: ""
  },
  
  {
    title: "The Barista Illustration",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "Some wonderful art at the exhibition and we really liked the comic book barista",
    link: "/barista-illustration",
    image: "./images/work/barista-illustration.jpg",
    banner: "./images/work/barista-illustration-screen.jpg",
    desc1: "This is an llustration or digital design or whatever you want to call it that I did for an exhibition a few years ago.",
    desc2: "The pretty rad coffee shop that was The Creative Common in Haverfordwest had a coffee themed art exhibition. The brief: create something cool and coffee based.",
    desc3: "I created this little guy whipping up a brew with an angry beard. My image sat alongside some pretty amazing pieces by some talented folks including the legend that is Lloyd the Graffiti and Death by Crabs.",
    desc4: "NOTE: I Googled 'Death by Crabs' to check out her Instagram account and it turns out that 'can a crab kill a human' is a question thats being asked by the internet folk! And worse than that, apparently they can. Da fuq?!",
    image2: "./images/work/barista-illustration-colours.jpg",
    image3: ""
  },
  
  {
    title: "The Dude T-Shirt Design",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "The kids love this t-shirt, we get through lots of them",
    link: "/dude-illustration",
    image: "./images/work/dude-tees.jpg",
    banner: "./images/work/dude-tees.jpg",
    desc1: "The guys at Newsurf Surf Shop stock a range of their own branded products. This is one of them.",
    desc2: "'The Dude', as he has become known, is featured on hoodies and t-shirts and continues to be a great seller for Newsurf.",
    desc3: "",
    desc4: "",
    image2: "./images/work/dude-illustration.jpg",
    image3: ""
  },

  {
    title: "Dead Sea Series",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "I like fish they're good swimmers. They don't drive well though",
    link: "/dead-fish-illustration",
    image: "./images/work/fish-illustration.jpg",
    banner: "./images/work/fish-illustration.jpg",
    desc1: "The company name 'Dead Sea' came from a series of illustrations I did. This is the last one remaining.",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },

  {
    title: "Salty Seadog T-Shirt Design",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "We want to see this design on more than just t-shirts",
    link: "/salty-seadog-illustration",
    image: "./images/work/salty-seadog-illustration.jpg",
    banner: "./images/work/salty-seadog-illustration.jpg",
    desc1: "A dead guy with a pipe. Man, I love my job.",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "Sweetshop Web Illustration",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "They look nearly as tasty as the real thing",
    link: "/sweetshop-illustration",
    image: "./images/work/sweetshop-scales-illustration.jpg",
    banner: "./images/work/sweetshop-illustration.jpg",
    desc1: "An illustration for a website banner",
    desc2: "Sometimes a photo just won't work when trying to create a childlike, Willy Wonka feeling.",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "Tin Hats Introduction",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "Thanks for bringing the idea to life, it looks fantastic",
    link: "/tin-hats-illustration",
    video : "https://player.vimeo.com/video/432753340",
    image: "./images/work/tin-hats-motion-design.jpg",
    banner: "",
    desc1: "Illustrated, animated introduction",
    desc2: "A short introduction sequence for a game concept",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "The Whales Collection",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "Everyone loves whales, right?",
    link: "/whales-illustration",
    image: "./images/work/whales-card-illustration.jpg",
    banner: "./images/work/whales-collection.jpg",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "The Eight Limbed Cephalopod",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "So octopus have arms and legs! Two legs and six arms. I know!",
    link: "/octopus-illustration",
    image: "./images/work/blue-octopus.jpg",
    banner: "./images/work/blue-octopus.jpg",
    desc1: "Octopus are squidgy, boneless, ocean creatures with 3 hearts and blue blood, why wouldn't you want to illustrate one?",
    desc2: "They are pretty clever little chaps apparently though the fact that they eat their own arms if they get bored sort of contradicts that.",
    desc3: "And if you have never seen the footage of an octopus escaping from a jam jar, why are you still here? Go to You Tube right now.",
    desc4: "Well, go on...",
    image2: "./images/work/pink-octopus.jpg",
    image3: ""
  },
  {
    title: "The Problems with Damp",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:true
    },
    video: 'https://player.vimeo.com/video/601881671',
    testimonial : "Thanks Chris, good job. It looks great! ",
    link: "/pcc-motion-illustration",
    image: "./images/work/washing-machine-illustration.jpg",
    banner: "",
    desc1: "Stop your home getting damp with PCC",
    desc2: "So who knew that your undies on the radiator can cause damp in your house? I didn't.",
    desc3: "Pembrokeshire County Council needed a more entertaining way of getting the damp message across to their tenants than a leaflet.",
    desc4: "And we've all learned something here today havent we?",
    image2: "",
    image3: ""
  },
  {
    title: "Astro Panda Character Design",
    seotitle: "illustration",
    show:true,
    skills: {
        websitedesign : false,
        webdevelopment: false,
        appdevelopment:false,
        illustration:true,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "The panda correctly predicted I'd run out of dosh before payday",
    link: "/astro-panda-illustration",
    image: "./images/work/astro-panda-illustration-square.jpg",
    banner: "./images/work/astro-panda-illustration.jpg",
    desc1: "The panda will predict your future. Maybe.",
    desc2: "",
    desc3: "",
    desc4: "",
    image2: "./images/work/astro-panda-logo-design.png",
    image3: ""
  },


   {
    title: "Preseli Glamping Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial: "We're all going on a summer holiday, no more working for a week or two...",
    link: "/preseli-glamping-website-development",
    image: "./images/work/preseli-glamping-website-design-screen.jpg",
    banner: "./images/work/preseli-glamping-website-design-full.jpg",
    desc1: "Who doesn't love camping, am I right? Now imagine turning up the dial and adding a 'G' where the 'C' used to be...Glamping!",
    desc2: "Preseli Glamping is fancy camping. Camping which is actually comfortable, not the kind of camping where you wake up with a dead leg and bad back and you say 'ooh yea I slept like a log' and then march off down the site with your newspaper tucked under your arm and your toothbrush poking out of your back pocket.",
    desc3: "The Preseli Glamping website uses Wordpress so the PG staff can update the website in-house without knowing any code whatsoever. And when someone wants to book a few nights away in their lovely geodesic dome (yes that's right) they just check the availability and book it right there and then.",
    desc4: "And when someone wants to book a few nights away in their lovely geodesic dome (yes that's right) they just check the availability and book it right there and then. Modern technology eh? I remember when the video remote control was attached to a wire.",
    image2: "./images/work/preseli-glamping-website-design-screen.jpg",
    image3: ""
  },
  {
    title: "Atlantic Edge Oysters Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "Why then the world's mine oyster, which I with sword will open",
    link: "/atlantic-edge-oysters-website-design",
    image: "./images/work/atlanticedgeoysters-website-design-screen.jpg",
    banner: "./images/work/atlanticedgeoysters-website-design.jpg",
    desc1: "The world could be your oyster, or just pick some up from these guys",
    desc2: "These guys are producing fresh oysters so clean and fresh you can eat them straight from the beach.",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },

 {
    title: "Gorilla ERP Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "The more you learn about the dignity of the gorilla, the more you want to avoid people",
    link: "/gorilla-website-design",
    image: "./images/work/gorilla-erp-website-design-screen.jpg",
    banner: "./images/work/gorilla-erp-website-design-banner.jpg",
    desc1: "Gorilla ERP use phrases like 'retail solution architect' and 'global implementation' so you know they're important. ",
    desc2: "They have clients and candidates all the world and deal with some pretty big companies. Companies even I've heard of.",
    desc3: "They manage the site and content themselves with the help of Wordpress and just call on yours truly for the special stuff.",
    desc4: "I even created the brand. The name is Gorilla so what could we have on the logo..? Sometimes the answer is right there in front of you",
    image2: "./images/work/gorilla-erp-website-design.jpg",
    image3: ""
  },

  {
    title: "Neurons Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial: "I am always ready to learn although I do not always like being taught",
    link: "/neurons-website-design",
    image: "./images/work/neurons-website-design-screen.jpg",
    banner: "./images/work/neurons-website-design-full.jpg",
    desc1: "Neurons is a learning platform where can children can...learn...stuff.",
    desc2: "This exactly the type of site we love to work on. It had challenges to overcome in terms of functionality and we got to draw lots of little characters.",
    desc3: "It's a learning platform and we learned something, it's already winning.",
    desc4: "Developed with Wordpress for in-house management.",
    image2: "./images/work/neurons-website-design-screen.jpg",
    image3: ""
  },
  
  {
    title: "St Davids Escapes Website Design",
    seotitle: "websitedesign",
    show:false,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "It's an absolute pleasure to work with Chris -  not only is he great at what he does, but he's also a lovely guy too",
    link: "/st-davids-escapes-website-design",
    image: "./images/work/st-davids-escapes-website-design-screen.jpg",
    banner: "./images/work/st-davids-escapes-website-design.jpg",
    desc1: "Stunning places to stay in and around St Davids",
    desc2: "A Wordpress website with calendar availability and booking functions.",
    desc3: "",
    desc4: "",
    image2: "",
    image3: ""
  },
  
  {
    title: "Sunny Recruitment Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial: "Find out what you like doing best, and get someone to pay you for doing it",
    link: "/sunny-recruitment-website-design",
    image: "./images/work/sunny-recruitment-website-design-screen.jpg",
    banner: "./images/work/sunny-website-design.jpg",
    desc1: "Everyday is a Sunny day in their office. When these guys wanted to rebrand and set up a brand new shiny website I was lucky enough to be asked to get involved.",
    desc2: "Seriously, if business success was based on the 'loveliness' of a company these guys would be out to lunch with Jeff Bezos. Probably having an avocado salad covered in pistachios.",
    desc3: "The website is built on Wordpress so the Sunny team can get involved and keep it up to date. The recruitment world is an ever changing landscape they tell me.",
    desc4: "I had a proper job once, I didnt like it. I got my hands dirty so it wasnt for me. If this design thing doesnt work out Im calling Sunny.",
    image2: "",
    image3: ""
  },
  {
    title: "Jarem Accountants Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial:"Dead Sea create websites, apps and designs that never fail to exceed expectations. Pleasure to work with them",
    link: "/jarem-accountants-website-design",
    image: "./images/work/jarem-website-design-screen.jpg",
    banner: "./images/work/jarem-website-design-full.jpg",
    desc1: "We worked closely with Brick Marketing on this one.",
    desc2: "Sometimes we team up with other companies to carry out projects. For this one Brick Marketing was on the blower.",
    desc3: "Brick did the marketing, we designed and developed the website. Easy peasy. Many hands and all that.",
    desc4: "A Wordpress website so Jarem can keep the site up to date.",
    image2: "",
    image3: ""
  },
  
  {
    title: "Logis De Canteau Website Design",
    seotitle: "websitedesign",
    show:true,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial: "Chris is patient and supportive and doesn't baffle non-technical folk such as us with the science. He's approachable and very professional. It's been a privilege and pleasure to work with him",
    link: "/logis-de-canteau-website-design",
    image: "./images/work/logis-de-canteau-website-design-screen.jpg",
    banner: "./images/work/logis-de-canteau-website-design.jpg",
    desc1: "An amazing hideaway in France",
    desc2: "Just check out this place and tell me you don't want to disappear here and write a novel.",
    desc3: "Developed on Wordpress this site is all about the images. ",
    desc4: "",
    image2: "",
    image3: ""
  },
  {
    title: "The Milkwood Project Website Design",
    seotitle: "websitedesign",
    show:false,
    skills: {
        websitedesign : true,
        webdevelopment: true,
        appdevelopment:false,
        illustration:false,
        graphicdesign:false,
        motiondesign:false
    },
    testimonial : "You nailed it!",
    link: "/milkwood-project-website-design",
    image: "./images/work/milkwood-project-website-design-screen.jpg",
    banner: "./images/work/milkwood-project-website-design.jpg",
    desc1: "Whittle a spoon or carve a bowl",
    desc2: "These guys are all about mental health. Get out into the woods away from everything.",
    desc3: "The site si developed using Wordpress so The Milkwood crew can keep their growing list of activities up to date.",
    desc4: "",
    image2: "",
    image3: ""
  },

  
 ];