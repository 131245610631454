import React from 'react';
import { Component } from "react";

class Services extends Component {
  render() {

    return(

      <div className="section" >
          <section className="container row">

            <div className="linkBox row g_grid_4"  data-aos="fade-up">   
              <a href="graphic-design-pembrokeshire-portfolio">

                <div className="serviceIcon">
                  <div className="serviceIcon-box blueBg"></div>
                  <div className="graphicDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="blue">Graphic</span> Design</h3>
                  <span className="link-arrow">&rarr;</span>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_4"  data-aos="fade-up">   
              <a href="illustration-design-portfolio">

                <div className="serviceIcon">
                  <div className="serviceIcon-box pinkBg"></div>
                  <div className="motionDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="pink">Illustrative</span> Design</h3>
                  <span className="link-arrow">&rarr;</span>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_4 omega"  data-aos="fade-up">   
              <a href="website-design-pembrokeshire-portfolio">

                <div className="serviceIcon">
                  <div className="serviceIcon-box orangeBg"></div>
                  <div className="websiteDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="orange">Website</span> Design</h3>
                  <span className="link-arrow">&rarr;</span>
                </div>

              </a>
            </div>

          </section>
        </div>

    )
  }
}

export default Services;