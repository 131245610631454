import React from 'react';
import { Component } from "react";
import $ from 'jquery';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/skull-crossbones.svg';
const images = require.context('./images', true);

export class Work extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: [],
	      check: 'check',
        filter:true
	    };

	}

	componentDidMount() {

    window.scrollTo(0, 0);
  
    var query = 0;
      switch(window.location.pathname) {
        case '/design-portfolio-pembrokeshire':
            $('.sidebar .whiteLine').addClass('active');
            document.title ="Website designer Pembrokeshire | Apps and Websites by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Website design, motion design, app development, graphic design. Contact us for a quote";
          break;
          case '/illustration-design-portfolio':
            $('.mainNav .pink').addClass('active');
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.illustrationfilter').show();
            document.title ="Illustration and graphic design | Illustrative work by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Images, icons and printables designed just for you. Contact us for a quote";
          break;
          case '/website-design-pembrokeshire-portfolio':
          $('.mainNav .orange').addClass('active');
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.websitefilter').show();
            $('.sidebar .orangeLine').addClass('active');
            this.setState({title : 'Website Design'});
            document.title ="Website designer Pembrokeshire | Apps and Websites by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Custom designed and developed websites, just for you. Contact us for a quote";
            break;
          case '/motion-design-pembrokeshire-portfolio':
            $('.mainNav .pink').addClass('active');
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.motionfilter').show();
            $('.sidebar .pinkLine').addClass('active');
            this.setState({title : 'Motion Design'});
            document.title ="Motion designer Pembrokeshire | Design for web, TV and film"; 
            document.getElementsByTagName("META")[2].content="Get your project moving with some motion by Dead Sea Design. Contact us for a quote";
            break;
          case '/graphic-design-pembrokeshire-portfolio':
            $('.mainNav .blue').addClass('active');
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.graphicfilter').show();
            $('.sidebar .blueLine').addClass('active');
            this.setState({title : 'Graphic Design'});
            document.title ="Graphic designer Pembrokeshire | Graphic Design by Dead Sea Design"; 
            document.getElementsByTagName("META")[2].content="Graphics and illustrations for print, web, film and TV. Contact us for a quote";
            break;
          case '/app-development-pembrokeshire-portfolio':
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.appfilter').show();
            $('.sidebar .greenLine').addClass('active');
            this.setState({title : 'App Development'});
            document.title ="App Developer Pembrokeshire | Native and PWA development"; 
            document.getElementsByTagName("META")[2].content="App development for all devices. Make your app idea a reality. Contact us for a quote";
            break;
          case '/logo-design-pembrokeshire-portfolio':
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.logofilter').show();
            $('.sidebar .purpleLine').addClass('active');
            this.setState({title : 'Logo, Branding and Identity Design'});
            document.title ="Logo Designer Pembrokeshire | Branding and Identity Design"; 
            document.getElementsByTagName("META")[2].content="Logo and branding design, get noticed, be recognised. Contact us for a quote";
            break;
          case '/web-development-pembrokeshire-portfolio':
            $('.portfolioItem-wrap').hide();
            $('.portfolioItem-wrap.devfilter').show();
            $('.sidebar .yellowLine').addClass('active');
            this.setState({title : 'Web Development'});
            document.title ="Web developer Pembrokeshire | Front and back end web development"; 
            document.getElementsByTagName("META")[2].content="Website and app development. From client side to database. Contact us for a quote";
            break;
          default:
            query = 0;
      }
  }

  filter(e) {
    
  }


  render() {

    return (
      <div className="darkBlueBg">


      
              <div className="portfolioWrap ">
                
                {worklist.map((work, index) => (
                 
                    <div key={index} className={`portfolioItem-wrap  
                          ${work.skills.websitedesign == true ? "websitefilter" : ""}
                          ${work.skills.webdevelopment == true ? "devfilter" : ""}
                          ${work.skills.illustration == true ? "illustrationfilter" : ""}
                          ${work.skills.graphicdesign == true ? "graphicfilter" : ""}
                          ${work.skills.motiondesign == true ? "motionfilter" : ""}
                          ${work.skills.logodesign == true ? "logofilter" : ""}
                        `} style={{backgroundImage:`url(${work.image})`}}>

              
                        <a href={work.link}>

                          <div className="portfolioItem-details row">
                            <div className="">
                              <h2>{work.title}</h2>
                            </div>
                            
                            <p>
                              <span className={`orange ${work.skills.websitedesign == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Website design </span>
                              <span className={`blue ${work.skills.graphicdesign ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Graphic Design </span>
                              <span className={`pink ${work.skills.motiondesign ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Motion Design </span>
                              <span className={`pink ${work.skills.illustration == true ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Illustration </span> 
                              <span className={`yellow ${work.skills.webdevelopment ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Website Development </span>
                              <span className={`green ${work.skills.appdevelopment ? "active animate__animated animate__fadeInDown" : "hidden"}`}> App Development </span> 
                              <span className={`purple ${work.skills.logodesign ? "active animate__animated animate__fadeInDown" : "hidden"}`}> Logo Design </span>
                            </p>
                          </div>
                        </a>
                    
                    </div>
                  
                ))}

              </div>
     
         <Controller>
          <Scene duration="100%" triggerHook="onLeave" classToggle="fadeIn" pin>
            <article className="section sectionReveal fullHeight has-left-menu darkBlueBg" >
              <section className="container textcenter">
                <header>
                  <p className="skull"><Skull /></p>
                  <h2>Get in touch today</h2>
                </header>
                
                <footer>
                  <a href="contact-website-designer" className="button purpleGradBg rounded mt20">Contact Us</a>
                </footer>
              </section>
            </article>
          </Scene> 
        </Controller>




      </div>


    );
    
  }
  
}

export default Work;
