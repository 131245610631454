import React from 'react';
import { Component } from "react";
import Services from './inc/Serviceicons';
import $ from 'jquery';
import ScrollMagic from "scrollmagic";

import { ReactComponent as Skull } from './images/skull-crossbones.svg';
import { ReactComponent as Fish } from './images/fish-bones.svg';
var traveller = require('./images/traveller-graphic-design.jpg');



export class Home extends React.Component {

  constructor(props) {
      super(props);
      this.controller = new ScrollMagic.Controller();
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
        check: 'check'
      };
  }

  componentDidMount() {
    document.title ="Graphic designer Pembrokeshire | Illustration and Websites by Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Design with a difference. Don't be like everyone else. Contact us for a quote";

    if($(window).width() < 901) {
      //$('.mainNav').slideUp();
      $('.menuToggle').removeClass('active')
    }


        function titleColours(element) {
 
                setTimeout(function(){
                        $(' .title1').addClass('blink1');
                },1000);
                setTimeout(function(){
                        $(' .title2').addClass('blink2');
                },2000);
                setTimeout(function(){
                        $(' .title3').addClass('blink3');
                },3000);
                setTimeout(function(){
                        $(' .title1').removeClass('blink1');
                        $(' .title2').removeClass('blink2');
                        $(' .title3').removeClass('blink3');
                },4000);
        }


        window.setInterval(function(){
                titleColours('.title');
        },5000);
  }

  render() {

    return (
      <div>

        <div className="banner">
          <div className="container textcenter">
            <p className="fish" data-aos="fade-down"><Fish/></p>

            <h1 className="title" id="title"><span className="title1 blink">Graphics</span>, <span className="title2 blink">Illustration</span> &amp; <span className="title3 blink">Website</span> Design</h1>
            <p>Bring your project or business to life. From characters to brochures to websites. We can help get your idea off the ground.</p>
          </div>
        </div>

        <div className="portfolio row" id="portfolio">
          <div className="portfolioOverflow">
            <div className="draw box" id="box1" data-aos="fade-up">
            </div>

            <div className="draw box" id="box2" data-aos="fade-up" data-aos-delay="500">
            </div>

            <div className="draw box" id="box3"  data-aos="fade-up">
            </div>

            <div className="draw box" id="box4" data-aos="fade-up" data-aos-delay="500">
            </div>

          </div>
        </div>

        <div className="container textcenter" data-aos="fade-up" >
          <p><a href="contact-website-designer" className="button purpleGradBg rounded">Get in Touch</a></p>
        </div>

        <article className="section" >
          <section className="container is-style-narrow textcenter">
            <header  data-aos="fade-up">
              <h2>So you need a website, a leaflet or something illustrating?</h2>
            </header>
            <main data-aos="fade-up">
              <p>Maybe you have an idea which could become the next flappy birds? We've got loads of experience doing this stuff, give us a shout.</p>
            </main>
          </section>
        </article>

        <Services />


        <article className="section" >
          <section className="container ">
            <header  data-aos="fade-up">
              <h2>Design, illustration and development to get your website or project moving</h2>
            </header>
            <main data-aos="fade-up">
              <p>And that's quite literally! Why have a static logo or banner when motion design can take it to the next level?  </p>
              <p><small><strong>Graphic design, illustration, websites and motion design</strong></small></p>
            </main>
            <footer>
              <p className="inlineButtons"><a href="contact-website-designer" className="button whiteBorder rounded mt20">Speak to Us</a> <a href="illustration-design-portfolio" className="button purpleGradBg rounded mt20">Some of our work</a></p>
            </footer>
          </section>
        </article>

        <article className="" >
          <div className="fullwidth">
            <img src={traveller} alt="Beer label design" className=""  data-aos="fade-up"/>
            <caption>Illustration and beer label design</caption>
          </div>
        </article>


      </div>


    );
    
  }
  
}

export default Home;
