import React from 'react';
import { Component } from "react";
import Services from './inc/Serviceicons';
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/app-dev-phone.svg';

var app1 = require('./images/app-development.svg');
const images = require.context('./images', true);

export class Faq extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Website design, graphic design and app development questions"; 
    document.getElementsByTagName("META")[2].content="Those niggling questions answered about all things design and development";

    $('.faq h3').click(function() {
      $(this).next('.answer').slideDown();
    })

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container is-style-narrow row">
            
            <div className="g_grid_12 pageContent">
              <main data-aos="fade-up">

                <p className="skull" data-aos="fade-down"><Skull/></p>

                <h1 className="textcenter">Got some questions?</h1>

                <caption className="textcenter">Click to reveal answer</caption>

                
                <div className="faq "  data-aos="fade-up">
                  <h3>How much does a website cost?</h3> 
                  <div class="answer">
                    <p>While my mother taught me not to answer a question with a question, I'm going to do just that.</p>
                    <p>How much is a house? It depends how many bedrooms it has, right? Or whether you can fit the Ford Cortina on the driveway or if the house is made of straw, sticks or bricks.</p>
                    <p>There are many factors involved in costing up a website. I can design and build a website and lighten your wallet by £1000 or I can build a unicorn of a website and have you singing to the tune of 5 times that. I do realise that asking the cost of something you know nothing about can be a bit unnerving so just fill in <a href="contact-website-designer">this form</a> with some details of what you need and I can get back to you with at least some ballpark figures and you dont even have to speak to anyone.</p>
                  </div>
                </div>


                <div className="faq"  data-aos="fade-up">
                  <h3>How much does an app cost?</h3> 
                  <div class="answer">
                    <p>Apps are the same price as websites (see question 1)</p>
                  </div>
                </div>

                <div className="faq"  data-aos="fade-up">
                  <h3>Do you build Wordpress websites?</h3> 
                  <div class="answer">
                    <p>Yes I do if thats what you need. I design and develop a lot of Wordpress websites.</p>
                  </div>
                </div>

                <div className="faq"  data-aos="fade-up">
                  <h3>How long does it take to build a website?</h3> 
                  <div class="answer">
                    <p>I realise I sound like a politician, never giving a straight answer but again, this depends. Though typically I have found a good portion of my projects take around 6 - 10 weeks from start to finish.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>How long does it take to build an app?</h3> 
                  <div class="answer">
                    <p>Usually a little longer than a website (see question 5)</p>
                  </div>
                </div>

                <div className="faq"  data-aos="fade-up">
                  <h3>Do I choose a design from a template?</h3> 
                  <div class="answer">
                    <p>No way man! Never. This never happens. Everything is designed and / or developed just for you.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>Do you design logos, branding, flyers and all that print stuff?</h3> 
                  <div class="answer">
                    <p>I sure do. Let me know what you need.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>What do you need from me to design my website / app / bumper sticker?</h3> 
                  <div class="answer">
                    <p>I need to know what you want on it and also what you are hoping to achieve from it. the purpose of a flyer for example might be to build brand awareness or it might be to get customers into your shop. Your website may need images. It may need images of your work, I dont have any of that stuff until you send it to me.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>Can you make my logo bigger?</h3> 
                  <div class="answer">
                    <p>No.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>My mate down the pub says that...?</h3> 
                  <div class="answer">
                    <p>Let me stop you there. If you have a mate that does what I do then he is bound to do it cheaper, he's your mate. If your mate is an expert at [insert skill here] then he/she is your guy/girl for the job.</p>
                  </div>
                </div>

                <div className="faq"  data-aos="fade-up">
                  <h3>Who is this Obi Creative chap?</h3> 
                  <div class="answer">
                    <p>Thats me as well. Obi is a name which I sort of inherited. I like drawing skulls, so I made up the name Dead Sea Design so I could draw skulls.</p>
                  </div>
                </div>

                <div className="faq"  data-aos="fade-up">
                  <h3>Why don't you wear a shirt and tank top like other techy sorts?</h3> 
                  <div class="answer">
                    <p>I like to think my scruffiness is part of my creative charm. Like Samson. While I may not dress like your standard think tank developer I do like a pot noodle and I'm partial to a game of table tennis.</p>
                  </div>
                </div>

                <div className=" faq"  data-aos="fade-up">
                  <h3>Is there anything more painful than kneeling on a lego brick?</h3> 
                  <div class="answer">
                    <p>While stubbing your toe on the bed leg comes a very close second, ultimately, the lego brick takes the crown.</p>
                  </div>
                </div>

      
            
              </main>

  
            </div>

          </section>

          
            <Services />
          <div className="container section small">
            <p>If you need something designing then get on the blower.</p>
             <p>I'm a graphic designer and developer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>

        </article>
      </div>


    );

    
  }
  
}

export default Faq;
