import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import './css/normalize.css';
import './App.css';
import './css/Contact.css';

export class Contact extends React.Component {

  constructor(props){
       super(props);

       this.state = {
           fields: {},
           errors: {},
           monika: "",
           firstname:"",
           service: "",
           subservice: "",
           websiteurl: "",
           description: "",
           contacttype:"",
           email:"",
           telephone:"",
           stage:1,
           validate: false,

       }

      this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount = () => {
    document.title ="Graphic design Pembrokeshire | Dead Sea Design"
    document.getElementsByTagName("META")[2].content="Get a free quote for your design project. Easy and quick!";
    //$('.mainNav').slideUp();

  }

  componentDidUpdate = () => {
    this.scrollToBottom()
  }


  handleChange(event) {

    const target = event.target;
    let val;

    if(target.name === 'monika') {
      var f = target.value.split(" ");
      this.setState({
        firstname: f[0],
        validate: (target.value == '' ? false : true)
      })
    }

    if(target.name === 'service') {

      if(target.value =='website' || target.value =='app') {
        this.setState({
          isWebsiteChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isWebsiteChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }

      if(target.value =='illustration') {
        this.setState({
          isIllustrationChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isIllustrationChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }

      if(target.value =='graphic') {
        this.setState({
          isGraphicChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isGraphicChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }

      if(target.value =='white') {
        this.setState({
          isWhitelabelChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isWhitelabelChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }

      if(target.value =='custom job') {
        this.setState({
          isSpecialChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isSpecialChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }

    }

    if(target.name === 'subservice') {
      if(target.value =='updates' || target.value =='changes' || target.value =='maintenance' || target.value =='broken'  ) {
          this.setState({
          isMaintenanceChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isMaintenanceChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }
      if(target.value =='new' ) {
          this.setState({
          isSpecialChosen: true,
          validate: (target.value == '' ? false : true)
        });
      } else {
        this.setState({
          isSpecialChosen: false,
          validate: (target.value == '' ? false : true)
        });
      }
      if(target.value =='chat' || target.value =='book' || target.value =='project' || target.value =='social') {
          this.setState({
          isCollectDetails: true,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    if(target.name === 'websiteurl') {
      if(!target.value =='' ) {
        this.setState({
          isWebsiteAdded: true,
          websiteurl: target.value,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    if(target.name === 'contacttype') {
      if(target.value =='chat') {
        this.setState({
          isCollectDetails: true,
          validate: (target.value == '' ? false : true)
        });
      }
      if(target.value =='desc') {
        this.setState({
          isDescriptionToAdd: true,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    if(target.name === 'whitelabelDescription') {
      if(!target.value =='' ) {
        this.setState({
          isWhiteLabelAdded: true,
          description: target.value,
          validate: true
        });
      }
    }

    if(target.name === 'description') {
      if(!target.value =='' ) {
        this.setState({
          isCollectDetails: true,
          description: target.value,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    if(target.name === 'email') {
      if(!target.value =='' ) {
        this.setState({
          isFormComplete: true,
          email: target.value,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    if(target.name === 'telephone') {
      if(!target.value =='' ) {
        this.setState({
          isFormComplete: true,
          telephone: target.value,
          validate: (target.value == '' ? false : true)
        });
      }
    }

    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  nextStage() {

    if(this.state.stage == 1) {
      this.setState({
        isNameComplete : true
      });
    }

    if(this.state.stage == 2) {
      if(this.state.isWebsiteChosen == true) {
        this.setState({
          isWebsiteService : true
        });
      }
      if(this.state.isIllustrationChosen == true) {
        this.setState({
          isIllustrationService : true
        });
      }
      if(this.state.isGraphicChosen == true) {
        this.setState({
          isSpecialService : true
        });
      }
      if(this.state.isWhitelabelChosen == true) {
        this.setState({
          isWhitelabelService : true
        });
      }
      if(this.state.isSpecialChosen == true) {
        this.setState({
          isSpecialService : true
        });
      }

      this.setState({
        isStage2Complete: true
      });
    }

    if(this.state.stage == 3) {

      if(this.state.isMaintenanceChosen == true) {
        this.setState({
          isMaintenanceService : true
        });
      }

      if(this.state.isSpecialChosen == true) {
        this.setState({
          isSpecialService : true
        });
      }

      if(this.state.isCollectDetails == true) {
        this.setState({
          isContactDetails : true
        });
      }

      if(this.state.isDescriptionToAdd == true) {
        this.setState({
          isMaintenanceServiceDetails : true
        });
      }

      if(this.state.isWhiteLabelAdded == true) {
        this.setState({
          isContactDetails : true
        });
      }

      this.setState({
        isStage3Complete: true
      });
      
    }

    if(this.state.stage == 4) {

      if(this.state.isWebsiteAdded == true) {
        this.setState({
          isMaintenanceServiceDetails : true
        });
      }

      if(this.state.isCollectDetails == true) {
        this.setState({
          isContactDetails : true
        });
      }

      if(this.state.isDescriptionToAdd == true) {
        this.setState({
          isMaintenanceServiceDetails : true
        });
      }

      this.setState({
        isStage4Complete: true
      });

    }

    if(this.state.stage == 5) {

      if(this.state.isDescriptionToAdd == true) {
        this.setState({
          isMaintenanceServiceDetails : true
        });
      }

      if(this.state.isCollectDetails == true) {
        this.setState({
          isContactDetails : true
        });
      }
    }

    if(this.state.isFormComplete == true) {
      this.saveDetails();
    }

    let next = this.state.stage + 1;
    this.setState({
      stage:next,
      validate:false
    });

  }

  scrollToBottom = () => {
    const { messageList } = this.refs;
    messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    console.log('scroll')
  }

  saveDetails = () => {
    var name = this.state.monika;
    var service = this.state.service;
    var subservice = this.state.subservice;
    var url = this.state.websiteurl;
    var description = this.state.description;
    var contacttype = this.state.contacttype;
    var telephone = this.state.telephone;
    var email = this.state.email;

        fetch("https://www.obicreative.co.uk/dead-sea-api/lead/create", {
            method:'POST', 
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              'Authorization': 'Basic ' + btoa('root:sdncjhfhYFVYigy$5DsFCvghjJKfjbb')
            },
            body: 'name=' + name +'&service=' + service +'&type=' + subservice +'&url=' + url + '&description=' + description + '&contacttype=' + contacttype + '&telephone=' + telephone + '&email=' + email
          }
        )
        .then(res => res.text())
        .then(
          (result) => {
            console.log(result)
            this.setState({
              isLoaded: true,
              items: result,
              isFinal: true
            });
          
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
          console.log(error)
        }
      )
  }

  render() {


    return (
      <div className="wrapper" ref="messageList">

        <article className="section pageIntro" >
          <section className="container textcenter">
            <header  data-aos="fade-up">
              <h2>Get in touch</h2>
            </header>
            <main data-aos="fade-up">
              <p>Call: <strong><a href="tel:01437 723196">01437 723196</a></strong>. Email: <strong><a href="mailto:hello@deadseadesign.co.uk">hello@deadseadesign.co.uk</a></strong></p>
              <p>Or start the conversation...</p>
            </main>
          </section>
        </article>

        <div className="quoteWrap">

          <div className="question question-name" >
            <div className="question-q">
              <h2>Hey there! Can I start by asking your name?</h2>
            </div>
            <div className="question-a">
              <input type="text" placeholder="e.g. Dave Smith" autoComplete="off" name="monika" value={this.state.monika} onChange={this.handleChange}  disabled={this.state.isNameComplete}/>
            </div>
          </div>

          <div className={`question stage2 ${this.state.isNameComplete ? "active animate__animated animate__fadeInLeft" : "hidden"}`} >
            <div className="question-q">
              <h2>Nice to meet you <strong>{this.state.firstname}</strong>. What can I do for you?</h2>
            </div>
            <div className="question-a">
                <select name="service" onChange={this.handleChange} disabled={this.state.isStage2Complete}>
                  <option value="">--Select--</option>
                  <option value="website">I need help with a website</option>
                  <option value="illustration">I need illustration work</option>
                  <option value="graphic">I need help with graphic design</option>
                  <option value="white">I need help with white label services</option>
                  <option value="custom job">I need help with something else</option>
                </select>
              </div>
          </div>

          <div className={`question stage3 ${this.state.isWebsiteService ? "active animate__animated animate__fadeInLeft" : "hidden"}`} >
            <div className="question-q">
              <h2>Cool, <strong>{this.state.service}s</strong> we can do! What sort of help do you need?</h2>
            </div>
            <div className="question-a">
                <select name="subservice" value={this.state.subservice} onChange={this.handleChange}  disabled={this.state.isStage3Complete}>
                  <option value="">--Select--</option>
                  <option value="new">I want a brand new shiny {this.state.service}</option>
                  <option value="updates">I want to add some bells and whistles to my {this.state.service}</option>
                  <option value="changes">I need some changes to my {this.state.service}</option>
                  <option value="maintenance">I'm looking for some regular help to maintain my {this.state.service}</option>
                  <option value="broken">My {this.state.service} is broken and I'm hoping you can fix it</option>
                  <option value="chat">I just want to talk to someone in plain English</option>
                </select>
            </div>
          </div>

          <div className={`question stage3 ${this.state.isIllustrationService ? "active animate__animated animate__fadeInLeft" : "hidden"}`} >
            <div className="question-q">
              <h2>Cool, I love doing <strong>{this.state.service}s</strong>. I'll get my pencil sharpened. But first a bit more info...</h2>
            </div>
            <div className="question-a">
                <select name="subservice" value={this.state.subservice} onChange={this.handleChange}  disabled={this.state.isStage3Complete}>
                  <option value="">--Select--</option>
                  <option value="social">I want some illustrations for social media</option>
                  <option value="book">I want some illustrations for a book</option>
                  <option value="project">I'm working on a project and I need illustrations</option>
                  <option value="chat">I'm not sure what I need yet</option>
                </select>
            </div>
          </div>

          <div className={`question stage3 ${this.state.isWhitelabelService ? "active animate__animated animate__fadeInLeft " : "hidden"}`}>
            <div className="question-q">
              <h2>OK, you're a business and you need some help huh? Can you tell us a little more?</h2>
            </div>
            <div className="question-a">
              <textarea name="whitelabelDescription" placeholder="e.g. We need some magic on a project..." onChange={this.handleChange}></textarea>
            </div>
          </div>

          <div className={`question stage3 stage4 ${this.state.isSpecialService ? "active" : "hidden"}`}>
            <div className="question-q">
              <h2>Woohoo! We love doing <strong>{this.state.service}s</strong>. Can you tell us more or would you prefer a chat?</h2>
            </div>
            <div className="question-a">
              <select name="contacttype" onChange={this.handleChange} disabled={this.state.isStage4Complete}>
              <option value="">- Select -</option>
              <option value="chat">I would prefer a chinwag</option>
              <option value="desc">I'm happy to spill the beans</option>
              </select>
            </div>
          </div>

          <div className={`question stage4 ${this.state.isMaintenanceService ? "active animate__animated animate__fadeInLeft" : "hidden"}`}>
            <div className="question-q">
              <h2>OK dokey, can you give us a link to your <strong>{this.state.service}</strong> so we can have a look?</h2>
            </div>
            <div className="question-a">
              <input type="text" name="websiteurl" placeholder="e.g. mywebsite.com" onChange={this.handleChange}  disabled={this.state.isStage4Complete}/>
            </div>
          </div>

          <div className={`question stage5 ${this.state.isMaintenanceServiceDetails ? "active animate__animated animate__fadeInLeft" : "hidden"}`}>
            <div className="question-q">
              <h2>Sweet! Can you give us a brief description of the work?</h2>
            </div>
            <div className="question-a">
              <textarea name="description" placeholder="e.g. We need some magic on a project..." onChange={this.handleChange}></textarea>
            </div>
          </div>


          <div className={`question stage5 stage6 ${this.state.isContactDetails ? "active animate__animated animate__fadeInLeft" : "hidden"}`}>
            <div className="question-q">
              <h2>Right <strong>{this.state.firstname}</strong>, we just need some contact details so we can get back to you.</h2>
            </div>
            <div className="question-a">
              <input type="text" name="telephone" placeholder="Telephone Number" onChange={this.handleChange}/>
              <input type="email" name="email" placeholder="Email Address" onChange={this.handleChange}/>
            </div>
          </div>

          <div className={`question finalStage ${this.state.isFinal ? "active animate__animated animate__fadeInLeft" : "hidden"}`}>
          <div className="question-q">
              <h2>Congratulations <strong>{this.state.firstname}</strong>!<br/>You just took  your first steps towards awesome. We'll be in touch soon. In the meantime, why not have a look at some of <a href="design-portfolio-pembrokeshire" className="black">our work</a>...?</h2>
            </div>
          </div>

          <button className="nextButton" disabled={!this.state.validate} onClick={() => this.nextStage()}>Next</button>
        </div> 
      </div>
    );

  }
  
}

export default Contact;
