import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/white-label-screen.svg';

var img1 = require('./images/ecs-website-design-screen.jpg');
const images = require.context('./images', true);

export class Whitelabel extends React.Component {

	constructor(props) {
	    super(props);
 
	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="White label website and graphic design | Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Behind the scenes design and development for companies with more work than they can handle";

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container row">
            
            <div className="g_grid_12 pageContent pageBanner">
              <main data-aos="fade-up">

                <p className="skull" data-aos="fade-down"><Skull/></p>

                <h1 className="textcenter"><span className="purple">White Label</span><br />Design and Development</h1>

                <img src={img1} alt="White label design" className=" rounded" data-aos="fade-up" />
                <caption>If this was 'white label' it would be our secret. Shhhh!</caption>

                <div className="section"  data-aos="fade-up">
                  <h2>Not all heroes wear capes</h2> 
                  <p>There are those that need a pat on the back for what they have done and those that are happy to be proud of what they have acheived silently. I regularly design and develop websites and applications for design agencies on a white label basis.</p>
                  <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some of my work</a></p>
                </div>
      
                <div className="section"  data-aos="fade-up">
                  <h2>There's no 'I' in team, but there is a 'me' so make your own rules.</h2>
                  <p>This means that agencies use me like I'm part of their in-house team. Im not actually employed by the company but work under their banner, wearing their company hat if you will. The company gets the work done without having to employ someone full time and fork out for a pension and a sandwich from Pret on Fridays, and in return they keep me busy with work. Its win win.</p>

                  <p><a href="contact-website-designer" className="button purpleGradBg rounded mt20">Get in touch</a></p>
                </div>
              </main>

  
            </div>

          </section>
           

          <div className="section container small">
             <p>If you need a native app or web app developed then get on the blower.</p>
             <p>I'm an app developer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>


        </article>
      </div>


    );

    
  }
  
}

export default Whitelabel;
