import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import ScrollMagic from "scrollmagic";

import { ReactComponent as Skull } from './images/skull-crossbones.svg';
var traveller = require('./images/ecs-website-design.jpg');

export class Websiteseo extends React.Component {

	constructor(props) {
	    super(props);
      this.controller = new ScrollMagic.Controller();
	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: [],
	      check: 'check'
	    };
	}

	componentDidMount() {
    document.title ="Website design Pembrokeshire | Website design by Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Website design and development for all businesses. Contact us for a quote";

    new ScrollMagic.Scene({triggerElement: "#title", reverse: false})
                .setClassToggle("#box1", "active") 
                .addTo(this.controller);
        new ScrollMagic.Scene({triggerElement: "#title", reverse: false})
                .setClassToggle("#box3", "active") 
                .addTo(this.controller);
        new ScrollMagic.Scene({triggerElement: "#portfolio", reverse: false})
                .setClassToggle("#box2", "active") 
                .addTo(this.controller);
        new ScrollMagic.Scene({triggerElement: "#portfolio", reverse: false})
                .setClassToggle("#box4", "active") 
                .addTo(this.controller);
        new ScrollMagic.Scene({triggerElement: "#title", reverse: false})
                .setClassToggle("#box5", "active") 
                .addTo(this.controller);
        new ScrollMagic.Scene({triggerElement: "#portfolio", reverse: false})
                .setClassToggle(".boxOverlay", "active") 
                .addTo(this.controller);

        function titleColours(element) {
                removeOverlay('.boxOverlay');

                setTimeout(function(){
                        $(' .title1').addClass('blink1');
                },1000);
                setTimeout(function(){
                        $(' .title2').addClass('blink2');
                },2000);
                setTimeout(function(){
                        $(' .title3').addClass('blink3');
                },3000);
                setTimeout(function(){
                        $(' .title1').removeClass('blink1');
                        $(' .title2').removeClass('blink2');
                        $(' .title3').removeClass('blink3');
                },4000);
        }

        function removeOverlay(element) {
          setTimeout(function(){
                $(element).fadeOut();
          },6400);
        }


        window.setInterval(function(){
                titleColours('.title');
        },5000);
  }

  render() {

    return (
      <div>

        <div className="banner">
          <div className="container textcenter">
            <p className="skull" data-aos="fade-down"><Skull/></p>

            <h1 className="title" id="title">Website Design Pembrokeshire</h1>
            
          </div>
        </div>

        <div className="portfolio row" id="portfolio">
          <div className="portfolioOverflow">
            <div className="draw box" id="box1">
              <div className="boxOverlay"></div>
            </div>

            <div className="draw box" id="box2" data-aos="fade-up">
              <div className="boxOverlay"></div>
            </div>

            <div className="draw box" id="box3" >
              <div className="boxOverlay"></div>
            </div>

            <div className="draw box" id="box4" data-aos="fade-up">
              <div className="boxOverlay"></div>
            </div>

            <div className="draw box" id="box5">
              <div className="boxOverlay"></div>
            </div>
          </div>
        </div>

        <div className="container textcenter" data-aos="fade-up" >
          <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded">See More Work</a></p>
        </div>

        <article className="section" >
          <section className="container">
            <header  data-aos="fade-up">
              <h2>So you need a website designing huh? I can do that!</h2>
            </header>
            <main data-aos="fade-up">
              <p>Maybe you have a website and need it updating or maybe you're a new business that needs a shiny new website design? Whatever your website wishes are, I can help.</p>
            </main>
            <footer>
              <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some of my work</a></p>
            </footer>
          </section>
        </article>

        <div className="section" >
          <section className="container row">

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="graphic-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box blueBg"></div>
                  <div className="graphicDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="blue">Graphic</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="website-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box orangeBg"></div>
                  <div className="websiteDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="orange">Website</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="motion-designer-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box pinkBg"></div>
                  <div className="motionDesignIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="pink">Motion</span> Design</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="app-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box greenBg"></div>
                  <div className="appIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="green">App</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6"  data-aos="fade-up">   
              <a href="web-development-pembrokeshire">

                <div className="serviceIcon">
                  <div className="serviceIcon-box yellowBg"></div>
                  <div className="webDevIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="yellow">Website</span> Development</h3>
                </div>

              </a>
            </div>

            <div className="linkBox row g_grid_6 omega"  data-aos="fade-up">   
              <a href="white-label-design">

                <div className="serviceIcon">
                  <div className="serviceIcon-box purpleBg"></div>
                  <div className="whiteLabelIcon serviceIcon-icon"></div>
                </div>

                <div className="linkBox-content">
                  <h3><span className="purple">White</span> Label</h3>
                </div>

              </a>
            </div>

          </section>
        </div>

        <article className="section" >
          <section className="container">
            <header  data-aos="fade-up">
              <h2>Why would you choose me to do your website design?</h2>
            </header>
            <main data-aos="fade-up">
              <p>Well why indeed? I could give you a load of waffle about value proposition and use terms like 'UX' and 'interactive web experiences'. Maybe you are in Pembrokeshire looking for website design but maybe you are not. Have a look at some of my past <a href="design-portfolio-pembrokeshire">website design</a> work. If you like what I do, ask me for a price. Ask around, people usually have nice things to say.</p>
            </main>
            <footer>
              <p><a href="design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some of my work</a></p>
            </footer>
          </section>
        </article>

        <article className="section" >
          <div className="container">
            <img src={traveller} alt="Beer label design" className="radius border parallax"  data-aos="fade-up"/>
            <caption>Website design and development</caption>
          </div>
        </article>

        <div className="section container small">
             <p>If you need a fancy website design that does something pretty special like remind you when your Grannys birthday is, I can do that too. Let me know what you want from your website and I'll advise what you need.</p>
             <p>I do website design Pembrokeshire but I work remotely so hit me up wherever you are.</p>
            <div className="tags">
             <p>Website design Pembrokeshire. Web development Pembrokeshire. App Design Pembrokeshire.</p>
            </div>
        </div>

        


      </div>


    );
    
  }
  
}

export default Websiteseo;
