import React from 'react';
import { Component } from "react";
import $ from 'jquery';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import worklist from "./data/work-data";
import { ReactComponent as Skull } from './images/website-design-screen.svg';

var website1 = require('./images/website-design-pembrokeshire.jpg');
var website2 = require('./images/sunny-website-design-banner.jpg');
const images = require.context('./images', true);

export class Website extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      error: null,
	      isLoaded: false,
	      items: []
	    };

	}

	componentDidMount() {
    document.title ="Website design Pembrokeshire | Apps and Graphics by Dead Sea Design"; 
    document.getElementsByTagName("META")[2].content="Website design and development from Apps to Wordpress. Contact us for a quote";

  }


  render() {

    return (
      <div>

       <article className="" >
          <section className="container row">
            
            <div className="g_grid_12 pageContent pageBanner">
              <main data-aos="fade-up">

              <p className="skull" data-aos="fade-down"><Skull/></p>

              <h1 className="textcenter"><span class="orange">Website</span> design<br />and development</h1>

              <img src={website1} alt="Website designer Pembrokeshire" className="rounded border " data-aos="fade-up" />
                <caption>Website for St Davids Escapes.</caption>

                <div className="section"  data-aos="fade-up">
                  <h2>Let's get techy now and talk about website development</h2> 
                  <p>Web development is a pretty loose term for building (developing) or maintaining a website or web application.</p><p>So if you need a new website developing I can do that. If you have a website and want it updating I can update it. If you have a website that you need maintaining etc etc.</p>
                  <p><a href="website-design-portfolio-pembrokeshire" className="button purpleGradBg rounded mt20">Some web development work</a></p>
                </div>

                <img src={website2} alt="Website designer Pembrokeshire" className="rounded border " data-aos="fade-up" />
                <caption>Getting all website-y with the guys from Sunny</caption>

                <div className="section"  data-aos="fade-up">
                  <h2><span class="orange">Website</span> design from funky ideas, to sketch, to server</h2>
                  <p>Website design is the term usually used for (surprise surprise) designing a website. This could mean designing a new website or it could mean giving an existing website a bit of a facelift.</p>

                  <p>The whole website design and development process is done in-house at Dead Sea Design. From sketches on the back of a [insert cliche here] to deployment on to the server, I've got your back!</p>

                  <p><a href="contact-website-designer" className="button purpleGradBg rounded mt20">Get in touch</a></p>
                </div>
              </main>

  
            </div>

          </section>


         

           <div className="section container small">
             <p>If you need a fancy web application that does something pretty special like remind you when your Grannys birthday is, I can do that too. Let me know what you want and I'll advise what you need.</p>
             <p>I'm a website designer in Pembrokeshire but I work remotely so hit me up wherever you are.</p>
          </div>


        </article>
      </div>


    );

    
  }
  
}

export default Website;
